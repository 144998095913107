var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-2m",
                    defaultEnd: "0d",
                    label: "발행일",
                    name: "requestDts",
                  },
                  model: {
                    value: _vm.searchParam.requestDts,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "requestDts", $$v)
                    },
                    expression: "searchParam.requestDts",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-dept", {
                  attrs: {
                    type: "search",
                    label: "작업요청 부서",
                    name: "reqDeptCd",
                  },
                  model: {
                    value: _vm.searchParam.reqDeptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "reqDeptCd", $$v)
                    },
                    expression: "searchParam.reqDeptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "WO_WORK_REQ_STEP_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "woWorkReqStepCd",
                    label: "진행상태",
                  },
                  model: {
                    value: _vm.searchParam.woWorkReqStepCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "woWorkReqStepCd", $$v)
                    },
                    expression: "searchParam.woWorkReqStepCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "작업요청 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: "500px",
            isExcelDown: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "single",
            rowKey: "woRequestId",
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["woWorkReqStepName"]
                    ? [
                        _c(
                          "q-chip",
                          {
                            attrs: {
                              color: _vm.getColors(props.row.woWorkReqStepCd),
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.row.woWorkReqStepName) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                  _c("c-btn", {
                    attrs: { label: "선택", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }